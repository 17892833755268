<template>
  <v-card elevation="0" class="d-flex flex-column justify-center align-center secondary rounded">
    <div v-if="isShown">
      <v-carousel
        class="rounded"
        height="150"
        hide-delimiters
        hide-delimiter-background
        :show-arrows="ad.model.creatives.length > 1"
      >
        <v-carousel-item v-for="(creative, index) in creatives" :key="index">
          <instream-preview
            :creative="{
              ...creative,
              type: ad.model.type,
              skipTime: ad.model.skipTime
            }"
            @end="isShown = $event"
          />
        </v-carousel-item>
      </v-carousel>
    </div>
    <v-card-actions v-if="!isShown" transition="scroll-x-transition" class="d-flex justify-center pa-2">
      <c-btn small depressed :label="$t('ads.show_preview')" color="primary" @click="isShown = $event" />
    </v-card-actions>
  </v-card>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import Ads from '@/services/classes/Ads.js'
  import InstreamPreview from '@/components/ads/InstreamForm/InstreamPreview.vue'

  export default {
    name: 'InstreamCreativePreview',
    components: {
      InstreamPreview,
      CBtn
    },
    props: {
      ad: {
        type: Ads,
        required: true
      }
    },
    data() {
      return {
        isShown: false
      }
    },
    computed: {
      creatives() {
        return this.ad.model.creatives
      }
    }
  }
</script>
